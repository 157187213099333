import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import PromoBanner from './components/PromoBanner';
import FeaturedProducts from './components/FeaturedProducts';
import ProductDetails from './components/ProductDetails';
import ShippingPage from './components/ShippingPage';
import OrderProcessing from './components/OrderProcessing';
import OrderDetails from './components/OrderDetails';
import OrdenProducts from './components/OrdenProducts';
import ScrollToTop from './components/ScrollToTop'; // Asegúrate de que el nombre sea correcto
import CartPage from './components/CartPage';
import ProtectedRoute from './components/admin/ProtectedRoute';
import CategoryPage from './components/CategoryPage';
import AboutUs from './components/AboutUs';

// Config inicial
const initialConfig = {
  apiUrl: 'https://carminadiaz.com',
  appName: 'carmina',
  correo: 'carminadiaztoledo@gmail.com',
  password: 'Rocio2016',
  envioGratis: '100',
  textBanner1: 'CarminaDiaz Moda Flamenca',
  textBanner2: '',
  tiktok: '',
  instagram: 'modacarminadiaz',
  whatsapp: '34699189442',
  lineaUno: 'Moda flamenco',
  lineaDos: 'Envio desde España',
  lineaTres: '',
  botonComprar: 'Comprar Ahora :)',
  textFooter: '© CarminaDiaz',
  video: false,
  theme: {
    background: '#bfe0ef',
    text: 'rgba(26, 16, 16, 0.75)',
    textHover: 'rgba(26, 16, 16, 1)',
    buttonBg: 'rgb(150,150,150)',
    buttonHover: '#383838',
    colorBackgroundBanner: '333333',
    colorTextBanner: '#000000',
    colorBackgroundShopnow: '#ffffff',
    colorTextBorderShopnow: '#990000',
    colorFooterBg: '#f1f1f1',
    colorFooterText: '#333',
  }
};

const App = () => {
  const [config, setConfig] = useState(initialConfig); // Inicializa el estado de la configuración

  useEffect(() => {
    document.title = config.appName; // Establecer el título dinámicamente
    applyTheme(config);
  }, [config]); // Asegúrate de actualizar el título cuando cambie el nombre de la app

  useEffect(() => {
    const fetchConfigFromApi = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/api/config`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const apiResponse = await response.json();
        setConfig((prevConfig) => ({
          ...prevConfig,
          ...apiResponse,
        })); // Actualiza el estado con la nueva configuración
      } catch (error) {
        console.error('Error al obtener los datos de la API:', error);
      }
    };

    fetchConfigFromApi(); // Llama a la función para obtener la configuración
  }, []); // Solo se ejecuta una vez al montar el componente

  const applyTheme = (config) => {
    const root = document.documentElement;
    root.style.setProperty('--color-background', config.theme.background);
    root.style.setProperty('--color-text', config.theme.text);
    root.style.setProperty('--color-text-hover', config.theme.textHover);
    root.style.setProperty('--color-navbar-bg', config.theme.background);
    root.style.setProperty('--color-offcanvas-bg', config.theme.background);
    root.style.setProperty('--color-button-bg', config.theme.buttonBg);
    root.style.setProperty('--color-button-hover', config.theme.buttonHover);
    root.style.setProperty('--color-icon', config.theme.text);
    root.style.setProperty('--color-icon-hover', config.theme.textHover);
    root.style.setProperty('--color-banner', config.theme.colorBackgroundBanner);
    root.style.setProperty('--color-text-banner', config.theme.colorTextBanner);
    root.style.setProperty('--color-background-shopnow', config.theme.colorBackgroundShopnow);
    root.style.setProperty('--color-text-border-shopnow', config.theme.colorTextBorderShopnow);
    root.style.setProperty('--footer-bg-color', config.theme.colorFooterBg);
    root.style.setProperty('--footer-text-color', config.theme.colorFooterText);
  };

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <PromoBanner config={config} />
            <FeaturedProducts config={config} />
          </>
        } />
        <Route path="/product/:id" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <ProductDetails config={config} />
          </>
        } />
        <Route path="/envio/:email" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <ShippingPage config={config} />
          </>
        } />
        <Route path="/cart" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <CartPage config={config} />
          </>
        } />
        <Route path="/orderProcessing/:email/:mobileNumber/:fullAddress/:typeShipping" element={
          <>
            <OrderProcessing config={config} />
          </>
        } />
        <Route path="/orderDetails/:id" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <OrderDetails config={config} />
          </>
        } />
        <Route path="/manage" element={
          <ProtectedRoute config={config} />
        } />
        <Route path="/about" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <AboutUs config={config} />
          </>
        } />
        <Route path="/category/:category" element={
          <>
            <Header config={config} />
            <Navbar config={config} />
            <CategoryPage config={config} />
          </>
        } />
      </Routes>
    </div>
  );
};

export default App;
