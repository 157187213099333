// src/components/ProductList.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ProductForm from './ProductForm';
import ProductItem from './ProductItem';
import ScrollToForm from './ScrollToForm';
import './admin.css';
//import { config } from '../config';
import './admin.css'; // Asegúrate de tener este archivo CSS

const ProductList = ({ config }) => {
    const [products, setProducts] = useState([]);
    const [editingProduct, setEditingProduct] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const formRef = useRef(null);
    const apiUrl = config.apiUrl;

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        const response = await axios.get(`${config.apiUrl}/api/products`);
        setProducts(response.data);
        setCategories([...new Set(response.data.map(product => product.category))]);
    };

    const handleEdit = (product) => {
        setEditingProduct(product);
        setIsFormVisible(true);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${apiUrl}/api/products/${id}`);
        fetchProducts();
    };

    const handleSave = () => {
        setEditingProduct(null);
        setIsFormVisible(false);
        fetchProducts();
    };

    const handleAddNew = () => {
        setEditingProduct(null);
        setIsFormVisible(true);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(selectedCategory === category ? null : category);
    };

    const handleProductClick = (product) => {
        setSelectedProduct(selectedProduct === product ? null : product);
    };

    return (
        <div className="admin-container-element">
            <h2>Productos</h2>
            <button className="admin-button" onClick={handleAddNew}>Añadir nuevo</button>
            <ScrollToForm isVisible={isFormVisible} />
            {isFormVisible && (
                <ProductForm 
                    ref={formRef}
                    productToEdit={editingProduct}  
                    config={config}
                    onSave={handleSave}  />
            )}
            {/* Lista de categorías */}
            <div className="category-list">
                <h3 style={{ fontWeight: 'bold' }}>Categorías</h3>
                <ul>
                    {categories.map((category, index) => (
                        <li key={index}>
                            <h4 onClick={() => handleCategoryClick(category)} style={{ fontStyle: 'italic', cursor: 'pointer' }}>
                                {category}
                            </h4>
                            {selectedCategory === category && (
                                <ul className="product-list">
                                    {products.filter(product => product.category === category).map(product => (
                                        <li key={product.id} style={{ display: 'flex', flexDirection: 'column', padding: '5px 0' }}>
                                            <span style={{ cursor: 'pointer' }} onClick={() => handleProductClick(product)}>
                                                {product.name}
                                            </span>
                                            {product.imageUrl && (
                                                <img src={product.imageUrl} alt={product.name} style={{ width: '50px', height: '50px', objectFit: 'cover', marginLeft: '10px' }} />
                                            )}
                                            {/* Mostrar el ProductItem justo debajo del nombre cuando está seleccionado */}
                                            <div className={`product-item-container ${selectedProduct === product ? 'visible' : ''}`}>
                                                <ProductItem
                                                    product={product}
                                                    onEdit={() => handleEdit(product)}
                                                    onDelete={() => handleDelete(product.id)}
                                                    config={config}
                                                />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ProductList;
