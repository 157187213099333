import React, { useState } from 'react';
import { FaEnvelope, FaTiktok, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './AboutUs.css'; // Asegúrate de tener un archivo CSS para los estilos

const AboutUs = ({ config }) => {

  const [formData, setFormData] = useState({
    name: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Crear el enlace mailto
    const mailtoLink = `mailto:${config.correo}?subject=Mensaje de ${formData.name}&body=${encodeURIComponent(formData.message)}`;
    
    // Redirigir al enlace mailto
    window.location.href = mailtoLink;

  };

  return (
    <div className="about-us-container">
      <h1 className="about-us-title">Conócenos</h1>
      <p className="about-us-description">
        Ofrecemos una línea de producción especializada en crear artículos personalizados. Si necesitas un producto a medida, ya sea en talla, diseño o estilo, ¡podemos hacerlo posible!
      </p>
      
      <h2 className="about-us-subtitle">Contacta con nosotros</h2>
      <p className="about-us-contact-description">
        Si tienes alguna pregunta o quieres solicitar un artículo personalizado, por favor, llena el siguiente formulario o contáctanos a través de nuestras redes sociales.
      </p>

      {/* Formulario de contacto */}
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nombre</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Mensaje</label>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="button" className="submit-button" onClick={handleSubmit}>Enviar</button>
      </form>

      {/* Redes sociales y correo */}
      <div className="d-flex justify-content-center social-icons-container">
        <a href={`mailto:${config.correo}`} className="social-icon" aria-label="Enviar correo">
          <FaEnvelope />
        </a>
        {config.tiktok ? 
          <a href={`https://www.tiktok.com/@${config.tiktok}?_t=8pxVIfRQge8&_r=1`} className="social-icon" aria-label="TikTok">
            <FaTiktok />
          </a> 
        : null }
        {config.instagram ? 
          <a href={`https://www.instagram.com/${config.instagram}`} className="social-icon" aria-label="Instagram">
            <FaInstagram />
          </a> 
        : null }
        {config.whatsapp ? 
          <a href={`https://wa.me/34${config.whatsapp}`} className="social-icon" aria-label="WhatsApp">
            <FaWhatsapp />
          </a> 
        : null }
      </div>
    </div>
  );
};

export default AboutUs;
